import {
	Component,
	OnInit,
	AfterViewInit,
	OnDestroy,
	inject,
} from '@angular/core'
import { CommonConfig, ConfigFile, Period } from '@eliq/core'
import {
	ResolutionType,
	PeriodType,
	CoreDataStoreService,
	Location,
	resolutionSorter,
	Fuel,
	HomePeriodService,
} from '@eliq/core'
import { TermsCheckerService } from '@eliq/feature/auth'
import {
	take,
	tap,
	switchMap,
	map,
	defaultIfEmpty,
	retryWhen,
	delay,
	retry,
	filter,
} from 'rxjs/operators'
import {
	AsyncSubject,
	Observable,
	Subject,
	Subscription,
	combineLatest,
	merge,
	of,
	timer,
} from 'rxjs'
import { UserHttpService } from '@eliq/core'
import { EnvironmentService } from '@eliq/data-access'
import { LocationHttpService } from '@eliq/core'
import { HomeProfile } from '@eliq/core'
import { ModalService } from '@eliq/ui/modal'
import { InsightsHomeProfileWizardModalComponent } from '@eliq/feature/insights/components/insights-home-profile-wizard-modal/insights-home-profile-wizard-modal.component'
import { AnnualHttpService } from '@eliq/ui/layout/home/cards/annual-card/services/annual-http-service'
import { JsonGetterService } from '@eliq/data-access'
import { DctHomeSetup } from '@eliq/feature/dynamic-capacity-tariffs/setup/dynamic-capacity-tariffs-home-setup'
import { TranslateModule } from '@ngx-translate/core'
import { TestingHelperComponent } from '@eliq/feature/test-helper/testing-helper/testing-helper.component'
import { PeakTariffsHomeCardComponent } from '@eliq/feature/dynamic-capacity-tariffs/components/peak-tariffs-home-card/peak-tariffs-home-card.component'
import { InfoCardComponent } from '@eliq/ui/layout/home/cards/info-card/info-card/info-card.component'
import { EnergyAdvisorCardContainerComponent } from '@eliq/feature/energy-advisor/energy-advisor-card/energy-advisor-card-container/energy-advisor-card-container.component'
import { ContractRenewalHomeCardComponent } from '@eliq/feature/contract-renewal/components/contract-renewal-home-card/contract-renewal-home-card.component'
import { SpinnerComponent } from '@eliq/ui'
import { BudgetHomeCardContainerComponent } from '@eliq/feature/budget/components/budget-home-card-container/budget-home-card-container.component'
import { HomechartContainerComponent } from '@eliq/feature/homechart/homechart-container/homechart-container.component'
import { PvHomeCardContainerComponent } from '@eliq/ui'
import { ConsumptionContainerComponent } from '@eliq/feature/consumption-card/consumption-container/consumption-container.component'
import { AnnualCardComponent } from '@eliq/ui/layout/home/cards/annual-card/annual-card/annual-card.component'
import { NgIf, AsyncPipe, JsonPipe, CommonModule, NgFor } from '@angular/common'
import { EliqThemeService } from '@eliq/theme'
import { ConfigAnnualCard } from '@eliq/data-access/services/config/models/config-annual-card.model'
import { AllConfigOptions } from '@eliq/core'
import {
	SimpleHomecardComponent,
	ConnectHomecardComponent,
} from '@eliq/ui/layout/home/cards'
import { EliqConnectService } from '@eliq/data-access/services/eliq-connect/eliq-connect.service'
import { SmrCardComponent } from '@eliq/feature/smr-card/smr-card.component'
import { MiniTransactionsContainerComponent } from '@eliq/feature'
import { Co2CardContainerComponent } from '@eliq/ui'
import { supportsBudget } from '@eliq/core/models/src/Location.utils'
import { CardComponent } from '@eliq/ui/common/components/card/card.component'
import { HomeProfileCardComponent } from './home-profile-card.component'

@Component({
	selector: 'eliq-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss'],
	standalone: true,
	imports: [
		CommonModule,
		Co2CardContainerComponent,
		NgIf,
		SmrCardComponent,
		ConnectHomecardComponent,
		SimpleHomecardComponent,
		AnnualCardComponent,
		ConsumptionContainerComponent,
		PvHomeCardContainerComponent,
		HomechartContainerComponent,
		BudgetHomeCardContainerComponent,
		SpinnerComponent,
		ContractRenewalHomeCardComponent,
		EnergyAdvisorCardContainerComponent,
		MiniTransactionsContainerComponent,
		InfoCardComponent,
		PeakTariffsHomeCardComponent,
		TestingHelperComponent,
		JsonPipe,
		TranslateModule,
		AsyncPipe,
		NgFor,
		CardComponent,
		HomeProfileCardComponent,
	],
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {
	public showEliqConnectCard$ = this.connectService.enableEliqConnect$
	public softShowEliqConnectCard = false

	// feature flags (set on init)
	public dctCardEnabled = false
	public annualCardEnabled = false

	public userName = ''
	public location: Location

	public primaryColor = 'initial'
	public primaryColorContrast = 'initial'

	public failed = false

	public setupLoaded = false

	public consumptionFailed = false

	public showSmrCard = this.env.getBaseUrl().includes('rebel') ? true : false // temporary check so we can merge this to main

	// consumption stuff
	public period: Period
	public periodToDate: Date
	public latestMonthWithDataPeriod: Period
	public fuels: string[]
	public mostRecentMonthWithData: Date
	public periodType: PeriodType
	public resolutionType: ResolutionType
	public resolutionTypeMonth = ResolutionType.Month
	public resolutionTypeDay = ResolutionType.Day
	public periodTypeMonth = PeriodType.Month
	public unit: 'energy' | 'cost' | 'm3' = 'energy'
	public units: string[]
	public loading = false
	private nChildrenLoading = 0

	public isProd: boolean = this.env.isProd()
	public isUAT: boolean = this.env.isUAT()

	public showRenewOption: boolean | undefined = undefined
	public contractDaysLeftDate: Date
	public agreementToRenewId = ''
	// elec fuel for prod/cons card
	public elecFuel: Fuel
	public hasProductionOrExport: boolean
	public hideBudget = false

	public config$ = new AsyncSubject<AllConfigOptions>()

	public hasBudget$: Observable<boolean> = this.config$.pipe(
		map(
			(c) =>
				!!(
					(c && c['enabled_home_cards'] && c['enabled_home_cards']['budget']) ||
					(c['homecards'] && c['homecards']['budget'])
				),
		),
		defaultIfEmpty(false),
		tap((hasBudget) => {}),
	)

	public enabledHomeCards$: Observable<
		Record<string, boolean | Record<string, any>>
	> = this.config$.pipe(
		map((c) => c && c['enabled_home_cards']),
		defaultIfEmpty({}),
		tap((enabled_home_cards) => {}),
	)

	getRecordEntries(
		record: boolean | Record<string, any> | undefined,
	): Array<Record<string, any>> {
		let result: Array<Record<string, any>> = []
		if (!record) return result

		result = Object.entries(record).map(([key, value]) => value)
		return result
	}

	// dct home card
	public startYear: number

	// subscriptions to take care of properly
	private activeLocationSubscription: Subscription

	// add annual elec consumption
	public now: Date

	constructor(
		public themeService: EliqThemeService,
		private connectService: EliqConnectService,
		private config: JsonGetterService,
		private modal: ModalService,
		private locHttp: LocationHttpService,
		private env: EnvironmentService,
		private userService: UserHttpService,
		private coreDataStore: CoreDataStoreService,
		private termsChecker: TermsCheckerService,
		private annualHttp: AnnualHttpService,
		private dctHomeSetup: DctHomeSetup,
		private homePeriod: HomePeriodService,
	) {}

	public integrationless = false

	public miniTransactionsErrored = false
	onMiniTransactionsError() {
		this.miniTransactionsErrored = true
	}

	locationSupportsBudget = this.coreDataStore.getActiveLocation().pipe(
		map((location) => {
			if (!location) return false
			return supportsBudget(location)
		}),
	)

	ngOnInit() {
		this.primaryColor = this.themeService.getProp('primary')
		this.primaryColorContrast = this.themeService.getProp('primary-contrast')

		if (this.env.isIntegrationless()) {
			this.integrationless = true
		}
		this.annualCardEnabled = this.env.getFeatureFlag('annual-card') ?? false
		this.dctCardEnabled =
			this.env.getFeatureFlag('dynamic-capacity-tariffs') ?? false
		this.coreDataStore.user.pipe(take(1)).subscribe((user) => {
			this.userName = user.forname || user.name
			this.showRenewOption = false
		})

		this.config.getConfig().subscribe((conf) => {
			this.config$.next(conf)
			this.config$.complete()
		})

		if (this.env.isKBC()) {
			this.hideBudget = true
			this.userService.getMandates().subscribe(
				(mandates) => {
					this.loading = false
					if (!mandates.length) {
						this.hideBudget = true
					} else {
						this.hideBudget = false
					}
				},
				(err) => {
					console.error('Could not get mandates in home.component.ts', err)
				},
			)
		}

		this.config.getHomeCardsConfig().subscribe((res) => {
			this.units =
				res.consumptionCard?.units !== undefined
					? res.consumptionCard.units
					: ['cost', 'energy', 'm3']
		})

		this.activeLocationSubscription = this.coreDataStore
			.getActiveLocation()
			.pipe(
				// retry if it errors 3 times with 1 second delay
				retry({ count: 5, delay: (err) => timer(1000) }),
			)
			.subscribe({
				error: (err) => {
					console.error('activeLocationSubscription error', err)
				},
				next: (location) => {
					if (!location) {
						this.failed = true
						this.consumptionFailed = true
						return
					}
					this.setupLoaded = false

					//this.coreDataStore.getActiveLocation()

					this.resolutionType = this.getResolutionToUse(location)
					this.periodType = this.getPeriodTypeFromResolutionType(
						this.resolutionType,
					)

					this.location = location
					this.fuels = location.fuels.map((fuel) => fuel.type)

					//Needed for PV
					const elecFuel = location.fuels.find((fuel) => fuel.type === 'elec')
					if (elecFuel) {
						this.elecFuel = elecFuel
					}

					const elecFuels = location.fuels.filter((f) => f.type === 'elec')
					this.activateElecFuelDependentFeatures(elecFuels)
					this.now = new Date()
					this.config.getInsightsConfig().subscribe((res) => {
						this.unit = res.defaultUnit // homechart
						if (this.dctCardEnabled) {
							this.startYear =
								this.dctHomeSetup.getStartYear(res.components, elecFuels) ??
								new Date().getFullYear()
							this.dctCardEnabled = !!this.startYear
						}
						const startDailyFromYesterday = res.periodTypes.filter(
							(pT) => pT.periodType === 'day',
						)[0].startFromYesterday
						const theTwoPeriods = this.homePeriod.createTheTwoPeriods(
							location.fuels,
							startDailyFromYesterday,
							this.unit,
							this.periodType,
						)
						if (theTwoPeriods.length > 1) {
							this.period = theTwoPeriods[0]
							this.latestMonthWithDataPeriod = theTwoPeriods[1]
						}
						this.periodToDate = this.homePeriod.getPeriodToDate(this.location)
						this.setupLoaded = true
						this.showHomeProfileIfNone()
					})
				},
			})
	}

	private activateElecFuelDependentFeatures(elecFuels) {
		if (this.dctCardEnabled) {
			// eslint-disable-next-line no-inner-declarations
			function isSmartMeter(fuel) {
				if (fuel.import) {
					return fuel.import.source === 'smart_meter'
				} else {
					return fuel.consumption.source === 'smart_meter'
				}
			}
			this.dctCardEnabled = elecFuels.some(isSmartMeter)
		}

		if (this.annualCardEnabled) {
			// eslint-disable-next-line no-inner-declarations
			function isManualMeter(fuel) {
				if (fuel.import) {
					return (
						fuel.import.source === 'non_smart_meter' ||
						fuel.import.source === 'load_curve_estimation'
					)
				} else {
					return (
						fuel.consumption.source === 'non_smart_meter' ||
						fuel.consumption.source === 'load_curve_estimation'
					)
				}
			}
			const manualMeters = elecFuels.some(isManualMeter)
			if (manualMeters) {
				this.showAnnualIfNoPreviousYearData()
			}
		}

		if (this.elecFuel) {
			this.hasProductionOrExport =
				!!this.elecFuel.production || !!this.elecFuel.export
		}
	}

	public annualCard$: Observable<boolean> = this.config.getAnnualCard().pipe(
		take(1),
		map((annualCard) => annualCard.homeEnabled),
	)

	public hideAnnual = () => {
		this.annualCardEnabled = false
	}

	private showAnnualIfNoPreviousYearData() {
		let userAddedPreviousYearData = false
		this.annualHttp
			.getAnnualEnergyFrom(this.location.id, new Date().getFullYear() - 1)
			.subscribe(
				(res: any) => {
					userAddedPreviousYearData = res.length > 0
				},
				(err: any) => {},
			)

		this.annualCardEnabled = !userAddedPreviousYearData
	}

	private homeProfile$ = this.coreDataStore.getActiveLocation().pipe(
		filter((location) => location !== null),
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		map((location) => location!.id),
		switchMap((locationId) =>
			this.fetchProfile(locationId).pipe(
				map((profile) => ({ locationId, profile })),
			),
		),
	)

	isHomeProfileCardVisible$ = combineLatest({
		config: this.config$,
		home: this.homeProfile$,
	}).pipe(
		map(({ config, home }) => {
			const isCardEnabled = config.enabled_home_cards['home-profile'] === true
			const isProfileIncomplete = home.profile.isIncomplete
			return isCardEnabled && isProfileIncomplete
		}),
	)

	private fetchProfile(locationId: number) {
		return this.locHttp
			.getLocationHomeProfile(locationId)
			.pipe(map((profile) => new HomeProfile(this.env, profile)))
	}

	private showHomeProfileIfNone() {
		const hasVisitedBefore = localStorage.getItem('INSIGHTS_HAS_VISITED_BEFORE')
		if (hasVisitedBefore == null) {
			localStorage.setItem('INSIGHTS_HAS_VISITED_BEFORE', 'YES')
			this.showHomeProfile()
		}
	}

	showHomeProfile() {
		this.homeProfile$
			.pipe(
				tap(({ locationId, profile }) => {
					if (
						typeof profile.isIncomplete &&
						this.env.getFeatureFlag('insights-home-profile-wizard')
					) {
						// if the first property in the wizard has been set, don't show wizard.
						// this is incase a user completes the wizard but clears localStorage
						// or changes device so hasVisitedBefore doesn't work.

						const dialogRef = this.modal.openModal(
							InsightsHomeProfileWizardModalComponent,
						)
						const instance = <InsightsHomeProfileWizardModalComponent>(
							dialogRef.componentInstance
						)
						instance.locationId = locationId
						instance.properties = profile.$properties
						instance.propertyGroups = profile.$propertyGroups
						dialogRef
							.afterClosed()
							.pipe(take(1))
							.subscribe(() => {
								this.ngOnInit()
							})
					}
				}),
			)
			.subscribe()
	}

	ngAfterViewInit() {
		this.termsChecker.checkTerms()
	}

	ngOnDestroy() {
		this.activeLocationSubscription?.unsubscribe()
	}

	public childLoadingUpdate = (loading: boolean) => {
		this.nChildrenLoading += loading ? 1 : -1
		if (this.nChildrenLoading < 0) this.nChildrenLoading = 0 // catch poorly behaving children messing with our state
		setTimeout(() => {
			this.loading = this.nChildrenLoading > 0
		})
	}

	private getPeriodTypeFromResolutionType(
		resolutionType: ResolutionType,
	): PeriodType {
		// we can only have two period types, seeing as we only can have two resolution types.
		if (resolutionType === ResolutionType.Day) return PeriodType.Month
		else if (resolutionType === ResolutionType.Month) return PeriodType.Year
		else return PeriodType.Year
	}

	/**
	 * Gets the resolution to use for this location. It can either be Daily or Monthly resolution
	 * @param location the location to get the fuels from
	 * @returns a resolution type, either day or month.
	 */
	private getResolutionToUse(location: Location): ResolutionType {
		// since the only two resolutions we are using are day and month, set all smaller than day to day.
		const biggestCommonRes = this.getLargestResolution(location)
		if (resolutionSorter(biggestCommonRes, ResolutionType.Day) < 1) {
			// if the biggest common resolution for this location is smaller than daily, (hour, 30min, etc)
			return ResolutionType.Day
		} else {
			return ResolutionType.Month
		}
	}

	private getLargestResolution(location: Location): ResolutionType | undefined {
		let biggestResolution: ResolutionType | undefined
		location.fuels.forEach((fuel) => {
			// first lap
			if (biggestResolution === undefined) {
				biggestResolution = fuel.resolution
			} else {
				if (resolutionSorter(fuel.resolution, biggestResolution) === 1) {
					// fuel.resolution is bigger than biggestResolution
					biggestResolution = fuel.resolution
				}
			}
		})

		if (!biggestResolution) {
			console.error(
				"No biggest resolution found which means we don't have any resolutions/fuels",
			)
			return undefined
		}

		return biggestResolution
	}
}
