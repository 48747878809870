import { FeatureFlags } from '@eliq/data-access'

export const environment = {
	featureFlags: FeatureFlags,
	integrationless: {
		enabled: true,
		useWelcomePage: false,
		usePreLoginPage: false
		},

	production: false,
	uat: true,
	defaultLang: 'en-GB',
	locales: ['en-GB'],

	clientName: 'santanderbank',
	clientId: 19860996377,

	eliqApiBaseUrl: 'https://client-santander-bank-api-uat.eliq.io',

	firebase: {
		apiKey: "AIzaSyD__ZsmH9oVjiO0gL-HK5j8Uu6FvH1UX48",
		authDomain: "santander-bank-c5a34.firebaseapp.com",
		projectId: "santander-bank-c5a34",
		storageBucket: "santander-bank-c5a34.appspot.com",
		messagingSenderId: "153260324895",
		appId: "1:153260324895:web:8718deb9450387925fbd2a",
		measurementId: "G-D3DEDR5FET"
	}
}


// AUTOMATICALLY ADDED GIT REVISION, DO NOT MODIFY
export const GIT_REVISION_TAG = '177b6dec9c32268a65ca3e6091a3bd39d18ed23f';
