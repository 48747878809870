import {
	CurrencyPipe,
	DecimalPipe,
	getCurrencySymbol,
	getLocaleCurrencyCode,
} from '@angular/common'
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core'

@Pipe({
	name: 'eliqNumber',
	standalone: true,
})
export class EliqNumberPipe implements PipeTransform {
	private myLocale: string
	constructor(
		@Inject(LOCALE_ID) input_locale: string,
		private currencyPipe: CurrencyPipe,
		private decimalPipe: DecimalPipe,
	) {
		this.myLocale = input_locale
	}

	/**
	 * Gets a list of distinct parts. Perform a .find operation on the list to find what you're looking for,
	 * either the number or currency_code.
	 * @param value the number you wish to format
	 * @param unit the unit, go either cost or energy
	 * @returns
	 */
	getTransformParts(
		value: number,
		unit: string,
	): { type: 'number' | 'currency_code'; text: string }[] {
		const result = this.transform(value, unit)
		const charLabels = [] as string[]
		for (let i = 0; i < result.length; i++) {
			const char = result.charAt(i)
			if (this.isCharNumeric(char)) charLabels.push('numeric')
			else if (this.isCharSpace(char)) charLabels.push('numeric')
			else charLabels.push('currency_code')
		}

		const firstNumericIndex = charLabels.findIndex((p) => p == 'numeric')
		const lastNumericIndex = charLabels.lastIndexOf('numeric')
		const myNumber = result.substring(firstNumericIndex, lastNumericIndex + 1)

		const firstCurrencyIndex = charLabels.findIndex((p) => p == 'currency_code')
		const lastCurrencyIndex = charLabels.lastIndexOf('currency_code')
		const myCurrencyCode = result.substring(
			firstCurrencyIndex,
			lastCurrencyIndex + 1,
		)

		const toReturn = [] as { type: 'number' | 'currency_code'; text: string }[]

		if (firstNumericIndex < firstCurrencyIndex) {
			// number first
			toReturn.push({
				type: 'number',
				text: myNumber,
			})
			toReturn.push({
				type: 'currency_code',
				text: myCurrencyCode,
			})
		} else {
			toReturn.push({
				type: 'currency_code',
				text: myCurrencyCode,
			})
			toReturn.push({
				type: 'number',
				text: myNumber,
			})
		}

		return toReturn
	}

	// is the character a part of some number? 1-9 and ., characters
	private isCharNumeric(char: string) {
		return (char >= '0' && char <= '9') || char == '.' || char == ','
	}

	private isCharSpace(char: string) {
		return char.trim() === ''
	}

	transform(value: number, unit: string, format?: string): string {
		let formatToUse = format

		// treated as 'energy' by default
		let postfix = ' kWh'

		if (unit === 'm3') {
			postfix = ' m³'
		} else if (unit !== 'cost') {
			// it's energy, we want to support not only kWh but also MWh
			if (value >= 1e3) {
				value = value / 1e3
				postfix = ' MWh'
			}
		}

		if (unit === 'cost') {
			return (
				this.currencyPipe.transform(
					value,
					this._getCurrencyCode() || undefined,
					undefined,
					value < 100 ? '1.2-2' : '1.0-0',
					this.myLocale,
				) ?? value?.toString()
			)
		}

		if (format) {
			if (format === '') {
				// user signifies that they want to use the default underlying pipe digitsInfo
				formatToUse = undefined
			} else {
				// some other string
				formatToUse = format
			}
		} else {
			if (value < 10) {
				formatToUse = '1.2-2'
			} else if (value < 100) {
				formatToUse = '1.1-1'
			} else {
				formatToUse = '1.0-0'
			}
		}

		return (
			this.decimalPipe.transform(value, formatToUse, this.myLocale) + postfix
		)
	}

	/**
	 * gets the currency symbol for the app determined locale
	 * @param format either wide or narrow as strings
	 */
	_getCurrencySymbol(format?: 'wide' | 'narrow') {
		return getCurrencySymbol(
			getLocaleCurrencyCode(this.myLocale) ?? '',
			format ? format : 'narrow',
		)
	}

	/**
	 * Gets the currencyCode for example SEK, EUR or USD dependant on the app defined locale
	 */
	_getCurrencyCode() {
		return getLocaleCurrencyCode(this.myLocale)
	}
}
